@tailwind base;
@tailwind components;
/* @import url('https://fonts.cdnfonts.com/css/sf-pro-display'); */

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.spinner {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: #CEDFD7 1.5em 0 0 0,
    #CEDFD7 1.1em 1.1em 0 0,
    #CEDFD7 0 1.5em 0 0,
    #CEDFD7 -1.1em 1.1em 0 0,
    #CEDFD7 -1.5em 0 0 0,
    #CEDFD7 -1.1em -1.1em 0 0,
    #CEDFD7 0 -1.5em 0 0,
    #CEDFD7 1.1em -1.1em 0 0;
  box-shadow: #CEDFD7 1.5em 0 0 0,
    #CEDFD7 1.1em 1.1em 0 0,
    #CEDFD7 0 1.5em 0 0,
    #CEDFD7 -1.1em 1.1em 0 0,
    #CEDFD7 -1.5em 0 0 0,
    #CEDFD7 -1.1em -1.1em 0 0,
    #CEDFD7 0 -1.5em 0 0,
    #CEDFD7 1.1em -1.1em 0 0;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.toggle-checkbox:checked {
  right: 0;
}

.toggle-checkbox:checked+.toggle-label {}

.react-datepicker__header {
  text-align: center;
  border-top-left-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
  font-weight: bold;
  background-color: white;
  border-bottom: 0;
}

.react-datepicker-time__input {
  border-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: rgb(55, 65, 81);
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: rgb(55, 65, 81);
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: rgb(55, 65, 81);
  color: #fff;
}

.react-datepicker__month--selected:hover,
.react-datepicker__month--in-selecting-range:hover,
.react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: rgb(55, 65, 81);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: rgb(55, 65, 81);
  color: #fff;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: rgb(55, 65, 81);
  color: #fff;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: rgb(55, 65, 81);
}

.react-datepicker-wrapper {
  width: 100%;
}

.dark {
  color: rgb(229, 231, 235) !important;
}

.rc-pagination {
  font-size: 12px;
  font-family: 'Arial';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0;

  margin-bottom: 0;
}

.rc-pagination>li {
  list-style: none;
}

.rc-pagination-total-text {
  float: left;
  height: 30px;
  line-height: 30px;
  list-style: none;
  padding: 0;
  margin: 0 8px 0 0;
  font-size: 14px;
}

.rc-pagination:after {
  content: ' ';
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}

.rc-pagination-item {
  cursor: pointer;
  border-radius: 6px;
  min-width: 31px;
  height: 31px;
  line-height: 31px;
  text-align: center;
  list-style: none;
  float: left;
  border: 1px solid rgb(229, 231, 235);
  background-color: transparent;
  margin-right: 8px;
}

.dark .rc-pagination-item {
  border: 1px solid rgba(75, 85, 99) !important;
}

.rc-pagination-item a {
  text-decoration: none;
}

.rc-pagination-item:hover {
  border-color: rgb(229, 231, 235);
}

.rc-pagination-item:hover a {}

.rc-pagination-item-disabled {
  cursor: not-allowed;
}

.rc-pagination-item-disabled:hover {
  border-color: rgb(229, 231, 235);
}

.rc-pagination-item-disabled:hover a {}

.rc-pagination-item-active {
  background-color: rgb(17, 24, 39);
  color: rgb(229, 231, 235);
  border-color: rgb(229, 231, 235);
}

.rc-pagination-item-active a {
  background-color: rgb(17, 24, 39);
}

.rc-pagination-item-active:hover a {
  background-color: rgb(17, 24, 39);
}

.rc-pagination-jump-prev:after,
.rc-pagination-jump-next:after {
  content: '•••';
  display: block;
  letter-spacing: 2px;
  font-size: 12px;
  margin-top: 1px;
}

.rc-pagination-jump-prev:hover:after,
.rc-pagination-jump-next:hover:after {}

.rc-pagination-jump-prev:hover:after {
  content: '«';
}

.rc-pagination-jump-next:hover:after {
  content: '»';
}

.rc-pagination-jump-prev-custom-icon,
.rc-pagination-jump-next-custom-icon {
  position: relative;
}

.rc-pagination-jump-prev-custom-icon:after,
.rc-pagination-jump-next-custom-icon:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  transition: all 0.2s;
  content: '•••';
  opacity: 1;
  display: block;
  letter-spacing: 2px;
  font-size: 12px;
  margin-top: 1px;
}

.rc-pagination-jump-prev-custom-icon .custom-icon-jump-prev,
.rc-pagination-jump-next-custom-icon .custom-icon-jump-prev,
.rc-pagination-jump-prev-custom-icon .custom-icon-jump-next,
.rc-pagination-jump-next-custom-icon .custom-icon-jump-next {
  opacity: 0;
  transition: all 0.2s;
}

.rc-pagination-jump-prev-custom-icon:hover:after,
.rc-pagination-jump-next-custom-icon:hover:after {
  opacity: 0;
}

.rc-pagination-jump-prev-custom-icon:hover .custom-icon-jump-prev,
.rc-pagination-jump-next-custom-icon:hover .custom-icon-jump-prev,
.rc-pagination-jump-prev-custom-icon:hover .custom-icon-jump-next,
.rc-pagination-jump-next-custom-icon:hover .custom-icon-jump-next {
  opacity: 1;
}

.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  margin-right: 8px;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  cursor: pointer;
  font-size: 10px;
  border-radius: 6px;
  list-style: none;
  min-width: 31px;
  height: 31px;
  line-height: 31px;
  float: left;
  text-align: center;
}

.rc-pagination-prev a:after {
  content: '‹';
  display: block;
}

.rc-pagination-next a:after {
  content: '›';
  display: block;
}

.rc-pagination-prev,
.rc-pagination-next {
  border: 1px solid rgb(229, 231, 235);
  font-size: 18px;
}

.dark .rc-pagination-prev,
.dark .rc-pagination-next {
  border: 1px solid rgba(75, 85, 99);
  font-size: 18px;
}

.rc-pagination-prev a,
.rc-pagination-next a {}

.rc-pagination-prev a:after,
.rc-pagination-next a:after {
  margin-top: -1px;
}

.rc-pagination-disabled {
  cursor: not-allowed;
}

.rc-pagination-disabled a {}

.rc-pagination-disabled .rc-pagination-item,
.rc-pagination-disabled .rc-pagination-prev,
.rc-pagination-disabled .rc-pagination-next {
  cursor: not-allowed;
}

.rc-pagination-disabled .rc-pagination-item:hover,
.rc-pagination-disabled .rc-pagination-prev:hover,
.rc-pagination-disabled .rc-pagination-next:hover {
  border-color: rgb(229, 231, 235);
}

.rc-pagination-disabled .rc-pagination-item:hover a,
.rc-pagination-disabled .rc-pagination-prev:hover a,
.rc-pagination-disabled .rc-pagination-next:hover a {}

.rc-pagination-disabled .rc-pagination-jump-prev,
.rc-pagination-disabled .rc-pagination-jump-next {
  pointer-events: none;
}

.rc-pagination-options {
  float: left;
  margin-left: 15px;
}

.rc-pagination-options-size-changer {
  float: left;
  width: 80px;
}

.rc-pagination-options-quick-jumper {
  float: left;
  margin-left: 16px;
  height: 31px;
  line-height: 31px;
}

.rc-pagination-options-quick-jumper input {
  margin: 0 8px;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid rgb(229, 231, 235);
  outline: none;
  padding: 3px 12px;
  width: 50px;
  height: 31px;
}

.rc-pagination-options-quick-jumper input:hover {
  border-color: rgb(229, 231, 235);
}

.rc-pagination-options-quick-jumper button {
  display: inline-block;
  margin: 0 8px;
  font-weight: 500;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid rgb(229, 231, 235);
  white-space: nowrap;
  padding: 0 15px;
  font-size: 12px;
  border-radius: 6px;
  height: 31px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border-color: rgb(229, 231, 235);
}

.rc-pagination-options-quick-jumper button:hover,
.rc-pagination-options-quick-jumper button:active,
.rc-pagination-options-quick-jumper button:focus {
  background-color: rgb(17, 24, 39);
  border-color: rgb(229, 231, 235);
}

.rc-pagination-simple .rc-pagination-prev,
.rc-pagination-simple .rc-pagination-next {
  border: none;
  height: 24px;
  line-height: 24px;
  margin: 0;
  font-size: 18px;
}

.rc-pagination-simple .rc-pagination-simple-pager {
  float: left;
  margin-right: 8px;
  list-style: none;
}

.rc-pagination-simple .rc-pagination-simple-pager .rc-pagination-slash {
  margin: 0 10px;
}

.rc-pagination-simple .rc-pagination-simple-pager input {
  margin: 0 8px;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid rgb(229, 231, 235);
  outline: none;
  padding: 5px 8px;
  min-height: 20px;
}

.rc-pagination-simple .rc-pagination-simple-pager input:hover {
  border-color: rgb(229, 231, 235);
}

.rc-pagination-simple .rc-pagination-simple-pager button {
  display: inline-block;
  margin: 0 8px;
  font-weight: 500;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0 8px;
  font-size: 12px;
  border-radius: 6px;
  height: 26px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border-color: rgb(229, 231, 235);
}

.rc-pagination-simple .rc-pagination-simple-pager button:hover,
.rc-pagination-simple .rc-pagination-simple-pager button:active,
.rc-pagination-simple .rc-pagination-simple-pager button:focus {
  background-color: transparent;
  border-color: rgb(229, 231, 235);
}

.light-button {
  background-color: #7ca7ad;
}
.lightGreen_bg{
  background-color: #f5f9f7;
}


/* form-card-payments */

.Input,
.p-FauxInput {
  border-radius: 58px !important;
  border: 1.5px solid red !important;
}

@media only screen and (max-width: 1024px) {

  .rc-pagination-item-after-jump-prev,
  .rc-pagination-item-before-jump-next {
    display: none;
  }
}


/*FULLPAGE SPINNER*/

/* Absolute Center Spinner */
.full-page-loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.full-page-loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.75),
      rgba(0, 0, 0, 0.75));

  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.75),
      rgba(0, 0, 0, 0.75));
}

/* :not(:required) hides these rules from IE9 and below */
.full-page-loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.full-page-loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(131, 117, 156, 0.75) 1.5em 0 0 0,
    rgba(131, 117, 156, 0.75) 1.1em 1.1em 0 0,
    rgba(131, 117, 156, 0.75) 0 1.5em 0 0,
    rgba(131, 117, 156, 0.75) -1.1em 1.1em 0 0,
    rgba(131, 117, 156, 0.75) -1.5em 0 0 0,
    rgba(131, 117, 156, 0.75) -1.1em -1.1em 0 0,
    rgba(131, 117, 156, 0.75) 0 -1.5em 0 0,
    rgba(131, 117, 156, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(131, 117, 156, 0.75) 1.5em 0 0 0,
    rgba(131, 117, 156, 0.75) 1.1em 1.1em 0 0,
    rgba(131, 117, 156, 0.75) 0 1.5em 0 0,
    rgba(131, 117, 156, 0.75) -1.1em 1.1em 0 0,
    rgba(131, 117, 156, 0.75) -1.5em 0 0 0,
    rgba(131, 117, 156, 0.75) -1.1em -1.1em 0 0,
    rgba(131, 117, 156, 0.75) 0 -1.5em 0 0,
    rgba(131, 117, 156, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*END - FULLPAGE SPINNER*/

@tailwind utilities;

.w-md {
  width: 28rem;
}

.overflow-slide {
  width: calc(100vw - 325px);
}

@media (min-width: 640px) {
  .sm\:w-md {
    width: 28rem;
  }
}

@media (min-width: 768px) {
  .md\:w-md {
    width: 28rem;
  }
}

@media (min-width: 1024px) {
  .lg\:w-md {
    width: 28rem;
  }

  .member-payment-card {
    max-width: 430px !important;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #DBDBDB;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #DBDBDB;
}

.chat-width {
  width: 450px;
  height: 550px
}

.modal-width {
  width: calc(100% - 256px);
  left: 256px;
}
.modal-width-2 {
  width: 100%;
  /* left: 256px; */
}

img:focus-visible {
  outline: unset !important;
}

button:focus-visible {
  outline: unset !important;
}

button:focus {
  outline: 0px !important;
}

.custom-fa-icon .svg-inline--fa.fa-w-16 {
  width: 1.25em !important;
  height: 1.25em !important;
}

.player-layout {
  grid-template-columns: 20% 80%;
}

.snap-mandatory+.left-0+.right-0 {
  left: 15px !important;
  right: unset !important;
  top: unset !important;
  bottom: 0px !important;
}

.snap-mandatory+.left-0+.right-0 button {
  position: absolute;
  left: 15px;
  bottom: 0px;
}

.snap-mandatory+.left-0 {
  top: unset !important;
  bottom: 0px !important;
  left: -15px;
}

.snap-mandatory+.left-0 button {
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.slider-height {
  height: 42rem;
}

.show-nav {
  display: none !important;
}

.cross-btn {
  top: 8px;
  right: 264px;
}

::placeholder {
  color: #003A4B !important;
  opacity: 1;
  /* Firefox */
}

.snap-mandatory+.left-0+.right-0>button>span>svg {
  width: 100px !important;
  height: 50px !important;
}

.snap-mandatory+.left-0>button>span>svg {
  width: 100px !important;
  height: 50px !important;
}

.current-program {
  height: 750px;
}

.program-content {
  height: 426;
}

.drop-player {
  top: 0px;
}

.slider-effect {
  position: absolute;
  top: 0px;
}

.custom-place::placeholder {
  color: #000 !important;
}

.cvv-card-border {
  border: 1.5px solid rgba(0, 0, 0, 0.16) !important;
  height: 51px !important;
}

.ElementsApp input {
  font-size: 14px !important;
  color: #000 !important;
  font-weight: 400 !important;
}

.ElementsApp {
  font-size: 14px !important;
  color: #000 !important;
  font-weight: 400 !important;
}

.custom-payment-border {
  border: 4px solid #003A4B !important;
}

.custom-position {
  left: calc(50% - 162px);
}

.custom-card-grid {
  display: grid;
  grid-template-columns: 55% 45%;
}

.custom-card-img {
  max-width: 80px;
  max-height: 80px;
  width: 80px;
  height: 80px;
}

.payment-card-height {
  min-height: 245px;
}

.custom-loader {
  position: absolute;
  height: 100%;
  background: #808080d1;
  z-index: 10000;
  -webkit-filter: blur(0px);
  filter: blur(0px);
  top: 0;
}

.time-zone-input .css-13vrg1d-control {
  border: 1px solid rgb(0, 58, 75);
  padding: 8px;
  min-height: 54px;
}

.cus-heath-bio {
  font-size: 14px;
}

.custom-form-text {
  font-size: 16px;
}

.gift-card:hover {
  transform: translateY(-6px);
  transition: 0.3s all ease-in-out;
}

@media (max-width: 1920px) {
  .frequency_audio {
    width: calc(100% - 70px) !important;
  }
}

@media (min-width: 1535px) {
  .payment-card-height {
    min-height: 260px;
  }

  .client-current-program {
    /* height: 580px; */
    overflow-y: scroll;
  }

  .save-btn {
    position: absolute;
    bottom: 64px;
  }
}

@media (max-width: 1500px) {
  .payment-card {
    width: 100% !important;
  }

  .frequency_audio {
    width: calc(100% - 15%) !important;
  }

  .slider-height {
    height: 28rem;
  }

  .current-program {
    height: 580px;
  }

  .program-content {
    height: 360;
  }

  .custom-card-grid {
    display: grid;
    grid-template-columns: 60% 40%;
  }

  .custom-loader {
    height: 100%;
  }

  .leading-height {
    max-height: 110px;
    overflow-y: auto;
  }

  .client-current-program {
    height: 580px;
    overflow-y: scroll;
  }
}

@media (max-width: 1356px) {
  .payment-card-height {
    min-height: 260px;
  }
}

@media (max-width: 1200px) {
  .player-layout {
    grid-template-columns: 15% 80%;
  }

  .slider-height {
    height: 30rem;
  }

  .payment-card-height {
    min-height: 280px;
  }

  .next-line {
    width: 50ch;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
  }

  .left-next-line {
    width: 25ch;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
  }

  .custom-form-text {
    font-size: 13px;
  }
}

@media (max-width: 1023px) {
  .overflow-slide {
    width: calc(100vw - 40px);
  }

  .full-width {
    width: 100%;
  }

  .card-width {
    min-width: 280px !important;
  }

  .modal-width {
    width: 100%;
    left: 0px;
  }

  .player-layout {
    grid-template-columns: 20% 80%;
  }

  .show-nav {
    display: flex !important;
  }

  .cross-btn {
    right: 8px;
  }

  .drop-player {
    top: -15px;
  }

  .custom-card-grid {
    display: grid;
    grid-template-columns: 100%;
  }

  .cus-current-program {
    height: 480px;
  }

  .current-program {
    height: 100%;
  }
}

@media (max-width: 640px) {
  .volume {
    width: 120px;
  }

  .cross-btn {
    top: 19px;
  }

  .custom-card-img {
    max-width: 70px;
    max-height: 70px;
    width: 70px;
    height: 70px;
  }

  .next-line {
    width: 25ch;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
  }

  .left-next-line {
    width: 18ch;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
  }
}

@media (max-width: 567px) {
  .chat-width {
    width: 90%;
    height: 450px;
    margin: 0px auto;
  }

  .search-bar {
    width: 100% !important;
  }

  .slider-height {
    height: calc(100vh - 285px) !important;
  }

  .slider-content {
    height: 88% !important;
    overflow: auto;
  }

  .snap-mandatory+.left-0+.right-0 {
    bottom: -10px !important;
  }

  .snap-mandatory+.left-0 {
    bottom: -10px !important;
  }

  .card-border {
    border-radius: 3rem !important;
  }

  .space-left {
    margin-left: auto !important;
  }

  .cus-current-program {
    height: 240px;
  }
}

@media (max-width: 420px) {

  /* .space-top{
    margin-top: 10px !important;
  } */
  .next-line {
    width: 15ch;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
  }

  .left-next-line {
    width: 14ch;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
  }
}


.main-content {
  width: calc(100% - 256px);
}


@media(max-width:1024px){
  .main-content {
    width:100%;
  }
}